<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title"
                    >遗产区和缓冲区社会环境年度监测记录</span
                >
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item prop="ND" label="年度" class="from-item-block">
                    <el-date-picker
                        v-model="formdata.dataDic.ND"
                        type="year"
                        placeholder="选择年份"
                        value-format="yyyy"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    prop="ZYKCDSL"
                    label="资源开采点数量（个）"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.ZYKCDSL"
                        @input="toNumber($event, 'ZYKCDSL', 4)"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="FMYXFW"
                    label="负面影响范围（㎡）"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.FMYXFW"
                        @input="toDecimal($event, 'FMYXFW', 4)"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="YZWRGYQYSL"
                    label="严重污染工业企业数量（个）"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.YZWRGYQYSL"
                        @input="toNumber($event, 'YZWRGYQYSL', 4)"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="LRMLSDYCQRKSL"
                    label="列入名录时的遗产区人口数量（人）"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.LRMLSDYCQRKSL"
                        @input="toNumber($event, 'LRMLSDYCQRKSL', 4)"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="DQYCQRKSL"
                    label="当前的遗产区人口数量（人）"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.DQYCQRKSL"
                        @input="toNumber($event, 'DQYCQRKSL', 4)"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="RKSSXQ"
                    label="人口疏散需求"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.RKSSXQ"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in RKSSXQList"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="LRMLSDHCQRK"
                    label="列入名录时的缓冲区人口（人）"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.LRMLSDHCQRK"
                        @input="toNumber($event, 'LRMLSDHCQRK', 4)"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="DQHCQRKSL"
                    label="当前的缓冲区人口数量（人）"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.DQHCQRKSL"
                        @input="toNumber($event, 'DQHCQRKSL', 4)"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item
                    prop="NYZZ"
                    label="农业总值（万元）"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.NYZZ"
                        @input="toNumber($event, 'NYZZ')"
                        @change="handleSdcyblChanged"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="GYZZ"
                    label="工业总值（万元）"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.GYZZ"
                        @input="toNumber($event, 'GYZZ')"
                        @change="handleSdcyblChanged"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="FWYZZ"
                    label="服务业总值（万元）"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.FWYZZ"
                        @input="toNumber($event, 'FWYZZ')"
                        @change="handleSdcyblChanged"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="SDCYBL"
                    label="三大产业比例"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.SDCYBL"
                        :disabled="!isEdit"
                        :maxlength="20"
                    ></el-input>
                </el-form-item> -->
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "ycqhhcqshhjndjc",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "1003",
                dataDic: {
                    glycbtid: this.heritageId,
                    ND: "",
                    ZYKCDSL: "",
                    FMYXFW: "",
                    YZWRGYQYSL: "",
                    LRMLSDYCQRKSL: "",
                    DQYCQRKSL: "",
                    RKSSXQ: "",
                    LRMLSDHCQRK: "",
                    DQHCQRKSL: "",
                    // NYZZ: "",
                    // GYZZ: "",
                    // FWYZZ: "",
                    // SDCYBL: "",
                },
            },
            rules: {
                ND: [
                    {
                        required: true,
                        message: "请选择年度",
                        trigger: "change",
                    },
                ],
                RKSSXQ: [
                    {
                        required: true,
                        message: "请选择人口疏散需求",
                        trigger: "change",
                    },
                ],
            },
            RKSSXQList: [],
            field1Data: [],
            field2Data: [],
            toMoneyProps: ["NYZZ", "GYZZ", "FWYZZ"],
        };
    },
    mounted() {
        this.GetEnum();
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID"]),
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "1003",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "RKSSXQ") {
                    this.RKSSXQList = v.dataItems;
                }
            });
        },
        // handleSdcyblChanged() {
        //     if (
        //         !this.formdata.dataDic.NYZZ ||
        //         !this.formdata.dataDic.GYZZ ||
        //         !this.formdata.dataDic.FWYZZ
        //     ) {
        //         this.formdata.dataDic.SDCYBL = "";
        //         return;
        //     }
        //     let nyzzData = this.formdata.dataDic.NYZZ;
        //     let gyzzData = this.formdata.dataDic.GYZZ;
        //     let fwyzzData = this.formdata.dataDic.FWYZZ;
        //     // 如果3个值中有负数或0，比例原封返回
        //     if (nyzzData <= 0 || gyzzData <= 0 || fwyzzData <= 0) {
        //         this.formdata.dataDic.SDCYBL = "";
        //         return;
        //     }
        //     if (
        //         parseInt(nyzzData) != nyzzData ||
        //         parseInt(gyzzData) != gyzzData ||
        //         parseInt(fwyzzData) != fwyzzData
        //     ) {
        //         // 如果3个值中有小数，计算各自的小数位数；不是小数的，默认为0.1
        //         let len1 = 0.1;
        //         let len2 = 0.1;
        //         let len3 = 0.1;
        //         if (nyzzData.indexOf(".") != -1) {
        //             len1 = nyzzData.split(".")[1].length;
        //         }
        //         if (gyzzData.indexOf(".") != -1) {
        //             len2 = gyzzData.split(".")[1].length;
        //         }
        //         if (fwyzzData.indexOf(".") != -1) {
        //             len3 = fwyzzData.split(".")[1].length;
        //         }
        //         // 计算小数位数最长的值的小数位数
        //         let max = len1;
        //         if (len2 > len1) max = len2;
        //         if (len3 > max) max = len3;
        //         // 将小数同时乘相同的倍数，变为整数
        //         nyzzData = nyzzData * Math.pow(10, max);
        //         gyzzData = gyzzData * Math.pow(10, max);
        //         fwyzzData = fwyzzData * Math.pow(10, max);
        //     }
        //     // 求3个数值的最大公约数
        //     let gcmData = this.getSpecialNum(
        //         parseInt(nyzzData),
        //         parseInt(gyzzData),
        //         parseInt(fwyzzData)
        //     );
        //     // 三个数值同时除以最大公约数得出比例
        //     this.formdata.dataDic.SDCYBL = `${parseInt(nyzzData) / gcmData} : ${
        //         parseInt(gyzzData) / gcmData
        //     } : ${parseInt(fwyzzData) / gcmData}`;
        // },
        // 计算最大公约数
        // getSpecialNum(num1, num2, num3) {
        //     let min = num1;
        //     let specilanum = new Object();
        //     if (num2 < num1) min = num2;
        //     if (num3 < min) min = num3;
        //     for (let i = min; i > 0; i--) {
        //         if (num1 % i == 0 && num2 % i == 0 && num3 % i == 0) {
        //             specilanum.GCM = i;
        //             break;
        //         }
        //     }
        //     return specilanum.GCM;
        // },
        GetDataNext() {
            if (
                this.formdata.dataDic.RKSSXQ ||
                this.formdata.dataDic.RKSSXQ === 0
            ) {
                this.formdata.dataDic.RKSSXQ =
                    this.formdata.dataDic.RKSSXQ + "";
            }
        },
    },
};
</script>

<style></style>
